import {
  Component,
  ComponentProps,
  createComputed,
  createSignal,
  JSX,
  on,
  runWithOwner,
} from "solid-js";
import { Dynamic } from "solid-js/web";
import c from "class-c";
import { Motion, Presence } from "solid-motionone";
import { createBoundSignal } from "solid-signals";
import { Event } from "solid-u";

import UObject from "@repo/utils/UObject";
import UPromise from "@repo/utils/UPromise";
import USolid from "@repo/utils-solid/USolid";

import CheckAnimation from "../animation/CheckAnimation";
import Delay from "../animation/Delay";
import Expand from "../animation/Expand";
import { useError } from "../error/ErrorProvider";
import Spinner from "../loaders/Spinner";

import styles from "./LoadableButton.module.scss";

type IntrinsicElementsWith<Filter> = {
  [Key in keyof JSX.IntrinsicElements]: JSX.IntrinsicElements[Key] extends Filter
    ? Key
    : never;
}[keyof JSX.IntrinsicElements];

declare namespace LoadableButton {
  type Status = "initial" | "pending" | "success";
  type AsProps = {
    class?: string;
    onClick?: JSX.EventHandlerUnion<
      // HTMLElement doesn't match HTMLDivElement
      any,
      MouseEvent
    >;
    children?: JSX.Element;
  };

  type As = ComponentProps<IntrinsicElementsWith<AsProps>> | AsProps;

  type Props<As extends LoadableButton.As = any> = UObject.Override<
    As,
    {
      as: Component<As>;
      class?: string;
      // Prop signal change will trigger state reset
      reset?: any;
      status?: LoadableButton.Status;
      onClick(
        e: Event.FromHandlerUnion<
          (As extends { onClick?: JSX.EventHandlerUnion<any, MouseEvent> }
            ? As
            : never)["onClick"]
        > & {
          cancel: typeof cancel;
          setPendingText(text?: string): void;
        },
      ): unknown | Promise<unknown>;
      text?: { pending?: string; success?: string };
      pendingOnly?: boolean;
    }
  >;
}

function LoadableButton<As extends LoadableButton.As>({
  as: _as,
  class: className,
  text,
  children,
  status: _status,
  onClick,
  pendingOnly,
  reset,
  ...props
}: D<LoadableButton.Props<As>>) {
  const error = useError();

  const [status, setStatus] = createBoundSignal<LoadableButton.Status>(
    "initial",
    [_status && (() => _status), () => {}],
  );
  const [pendingText, setPendingText] = createSignal<string>();

  let pendingRef: HTMLDivElement = null!;

  return (
    // @ts-expect-error
    <Dynamic
      component={_as}
      {...props}
      class={c`${styles["loadable-button"]} ${className}`}
      onClick={(e: any) => {
        if (_status || status() !== "initial") return;

        const owner = USolid.Owner.make();

        (async () => {
          const res = onClick!(Object.assign(e, { cancel, setPendingText }));
          if (!(res instanceof Promise)) return;

          setStatus("pending");

          let wasReset = false;
          runWithOwner(owner, () =>
            createComputed(
              on(
                () => reset,
                () => {
                  setStatus("initial");
                  wasReset = true;
                },
                { defer: true },
              ),
            ),
          );

          await res
            .then(() => {
              if (wasReset) return;
              setStatus("success");
              return UPromise.delay(LoadableButton.successDuration);
            })
            .finally(() => {
              setStatus("initial");
            });
        })()
          .catch((e) => {
            if (e !== cancel && e != null) error.capture(e);
          })
          .finally(() => {
            owner.dispose();
            setPendingText();
          });
      }}
    >
      <Motion.main
        animate={{
          width:
            status() === "pending" ? `${pendingRef.clientWidth}px` : undefined,
        }}
      >
        {children}
      </Motion.main>
      <Presence>
        {status() === "pending" && (
          <Motion.div
            ref={pendingRef}
            class={styles.pending}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Spinner />{" "}
            <span class="pl-0.5">
              {pendingText() || text?.pending || "Loading..."}
            </span>
          </Motion.div>
        )}
        {status() === "success" && !pendingOnly && (
          <Motion.div
            class={styles.success}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Delay for={300}>
              <CheckAnimation />
              <Delay for={300}>
                <Expand direction="x">
                  <div class="pl-0.5">{text?.success || "Success"}</div>
                </Expand>
              </Delay>
            </Delay>
          </Motion.div>
        )}
      </Presence>
    </Dynamic>
  );
}

LoadableButton.successDuration = 1200;
LoadableButton.afterSuccess = (fn: () => void) => {
  setTimeout(fn, LoadableButton.successDuration);
};

const cancel = Symbol("LoadableButton.cancel");

export default LoadableButton;
